/**
 * This module serves as an Async wrapper for LocalStorage
 * Why? Because the Provider API `storage` option allows an async storage
 */
export async function setItem(key, value) {
  localStorage.setItem(key, value);
}
export async function getItem(key) {
  return localStorage.getItem(key);
}
export async function removeItem(key) {
  localStorage.removeItem(key);
}