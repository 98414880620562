// https://stackoverflow.com/a/3561711/6519037
function escapeRegex(string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}
function wrapInRegex(value) {
  if (typeof value === 'string') {
    // TODO in the next major we should change this to `new RegExp(value)` so that the user can control start/end characters
    return new RegExp(`^${value}$`); // throws if invalid regex
  }
  if (value instanceof RegExp) {
    return value;
  }
  return undefined;
}
export default function getAllowedHosts(companionAllowedHosts, companionUrl) {
  if (companionAllowedHosts) {
    const validate = value => {
      if (!(typeof value === 'string' && wrapInRegex(value)) &&
      // wrapInRegex throws if invalid regex
      !(value instanceof RegExp)) {
        throw new TypeError(`The option "companionAllowedHosts" must be one of string, Array, RegExp`);
      }
    };
    if (Array.isArray(companionAllowedHosts)) {
      companionAllowedHosts.every(validate);
    } else {
      validate(companionAllowedHosts);
    }
    return companionAllowedHosts;
  }

  // if it does not start with https://, prefix it (and remove any leading slashes)
  let ret = companionUrl;
  if (/^(?!https?:\/\/).*$/i.test(ret)) {
    ret = `https://${companionUrl.replace(/^\/\//, '')}`;
  }
  ret = new URL(ret).origin;
  ret = escapeRegex(ret);
  return ret;
}
export function isOriginAllowed(origin, allowedOrigin) {
  const patterns = Array.isArray(allowedOrigin) ? allowedOrigin.map(wrapInRegex) : [wrapInRegex(allowedOrigin)];
  return patterns.some(pattern => (pattern == null ? void 0 : pattern.test(origin)) || (pattern == null ? void 0 : pattern.test(`${origin}/`))); // allowing for trailing '/'
}