var _GoogleDrivePicker;
import { h } from 'preact';
import { UIPlugin } from '@uppy/core';
import { GooglePickerView } from '@uppy/provider-views';
import { GoogleDriveIcon } from '@uppy/provider-views/lib/GooglePicker/icons.js';
import { RequestClient, tokenStorage } from '@uppy/companion-client';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore We don't want TS to generate types for the package.json
const packageJson = {
  "version": "0.3.3"
};
import locale from './locale.js';
export default class GoogleDrivePicker extends UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.type = 'acquirer';
    this.icon = GoogleDriveIcon;
    this.defaultLocale = locale;
    this.handleFilesPicked = async (files, accessToken) => {
      this.uppy.addFiles(files.map(_ref => {
        let {
          id,
          mimeType,
          name,
          ...rest
        } = _ref;
        return {
          source: this.id,
          name,
          type: mimeType,
          data: {
            size: null // defer to companion to determine size
          },
          isRemote: true,
          remote: {
            companionUrl: this.opts.companionUrl,
            url: `${this.opts.companionUrl}/google-picker/get`,
            body: {
              fileId: id,
              accessToken,
              ...rest
            },
            requestClientId: GoogleDrivePicker.requestClientId
          }
        };
      }));
    };
    this.render = () => h(GooglePickerView, {
      storage: this.storage,
      pickerType: "drive",
      uppy: this.uppy,
      i18n: this.i18n,
      clientId: this.opts.clientId,
      apiKey: this.opts.apiKey,
      appId: this.opts.appId,
      onFilesPicked: this.handleFilesPicked
    });
    this.id = this.opts.id || 'GoogleDrivePicker';
    this.storage = this.opts.storage || tokenStorage;
    this.defaultLocale = locale;
    this.i18nInit();
    this.title = this.i18n('pluginNameGoogleDrivePicker');
    const client = new RequestClient(uppy, {
      pluginId: this.id,
      provider: 'url',
      companionUrl: this.opts.companionUrl,
      companionHeaders: this.opts.companionHeaders,
      companionCookiesRule: this.opts.companionCookiesRule
    });
    this.uppy.registerRequestClient(GoogleDrivePicker.requestClientId, client);
  }
  install() {
    const {
      target
    } = this.opts;
    if (target) {
      this.mount(target, this);
    }
  }
  uninstall() {
    this.unmount();
  }
}
_GoogleDrivePicker = GoogleDrivePicker;
GoogleDrivePicker.VERSION = packageJson.version;
GoogleDrivePicker.requestClientId = _GoogleDrivePicker.name;