import { h } from 'preact';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore untyped
import VirtualList from '@uppy/utils/lib/VirtualList';
import { useEffect, useState } from 'preact/hooks';
import Item from "./Item/index.js";
function Browser(props) {
  const {
    displayedPartialTree,
    viewType,
    toggleCheckbox,
    handleScroll,
    showTitles,
    i18n,
    isLoading,
    openFolder,
    noResultsLabel,
    virtualList,
    utmSource
  } = props;
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false);

  // This records whether the user is holding the SHIFT key this very moment.
  // Typically, this is implemented using `onClick((e) => e.shiftKey)` -
  // however we can't use that, because for accessibility reasons
  // we're using html tags that don't support `e.shiftKey` property (see #3768).
  useEffect(() => {
    const handleKeyUp = e => {
      if (e.key === 'Shift') setIsShiftKeyPressed(false);
    };
    const handleKeyDown = e => {
      if (e.key === 'Shift') setIsShiftKeyPressed(true);
    };
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  if (isLoading) {
    return h("div", {
      className: "uppy-Provider-loading"
    }, typeof isLoading === 'string' ? isLoading : i18n('loading'));
  }
  if (displayedPartialTree.length === 0) {
    return h("div", {
      className: "uppy-Provider-empty"
    }, noResultsLabel);
  }
  const renderItem = item => h(Item, {
    viewType: viewType,
    toggleCheckbox: event => {
      var _document$getSelectio;
      event.stopPropagation();
      event.preventDefault();
      // Prevent shift-clicking from highlighting file names
      // (https://stackoverflow.com/a/1527797/3192470)
      (_document$getSelectio = document.getSelection()) == null || _document$getSelectio.removeAllRanges();
      toggleCheckbox(item, isShiftKeyPressed);
    },
    showTitles: showTitles,
    i18n: i18n,
    openFolder: openFolder,
    file: item,
    utmSource: utmSource
  });
  if (virtualList) {
    return h("div", {
      className: "uppy-ProviderBrowser-body"
    }, h("ul", {
      className: "uppy-ProviderBrowser-list"
    }, h(VirtualList, {
      data: displayedPartialTree,
      renderRow: renderItem,
      rowHeight: 31
    })));
  }
  return h("div", {
    className: "uppy-ProviderBrowser-body"
  }, h("ul", {
    className: "uppy-ProviderBrowser-list",
    onScroll: handleScroll,
    role: "listbox"
    // making <ul> not focusable for firefox
    ,
    tabIndex: -1
  }, displayedPartialTree.map(renderItem)));
}
export default Browser;